.ceCePd {
  position: absolute;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1234px;
  height: 44px;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  padding: 60px 30px;
  flex-wrap: wrap;
  z-index: 3;
  top: 10;
}

.ceCePd1 {
  position: absolute;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1234px;
  height: 44px;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  padding: 60px 30px;
  flex-wrap: nowrap;
  z-index: 3;
  top: 10;
}

.selectLang {
  padding: 0px 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.coGvvV {
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 10px;
  width: 220px;
}

.ant-dropdown-menu {
  background-color: transparent !important;
  outline: none;
  box-shadow: none !important;
}

.hIBkFg {
  font-size: 72px;
  font-family: Arial-BoldMT, Arial;
  font-style: normal;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 84px;
  background: linear-gradient(180deg, #6F0349 0%, #4B158C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.eHSsyh {
  height: 890px;
  background: linear-gradient(189.16deg, rgb(67, 22, 219) 13.57%, rgb(144, 118, 231) 98.38%);
  position: relative;
  overflow: hidden;
}


.dNzWzy {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: repeat;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/stars.svg");
  height: 224px;
  top: 10px;
  display: none;
}

.bXnDvb {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 137px;
  height: 900px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/hero-wave1.svg");
}

.cDDgVz {
  position: absolute;
  width: 100%;
  height: 800px;
  backdrop-filter: blur(60px);
}

.jHdieN {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 380px;
  left: 0px;
  height: 600px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/hero-wave2.svg");
}

.haRxZf {
  position: absolute;
  width: 100%;
  top: 520px;
  height: 500px;
  clip-path: url(#clip);
  transform-origin: left top;
  background: linear-gradient(rgba(31, 31, 71, 0.25) -18.72%, rgb(31, 31, 71) 37.6%);
}

.kNHqgu {
  position: absolute;
  height: 1500px;
  width: 100%;
  overflow: hidden;
  display: block;
}

.jMbBOM {
  position: relative;
  padding-top: 85px;
  padding-bottom: 20px;
  width: 100%;
}


.jTMytU {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave1);
  top: 0px;
  height: 250px;
  background: linear-gradient(200.44deg, rgb(67, 22, 219) 13.57%, rgb(144, 118, 231) 58.38%);
  opacity: 0.2;
}

.jTXiRU {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave2);
  top: 20px;
  height: 250px;
  background: linear-gradient(132.86deg, rgb(255, 36, 36) -4.28%, rgb(193, 52, 122) 14.43%, rgb(73, 30, 184) 34.1%);
}

.hDCskI {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave3);
  top: 40px;
  height: 500px;
  background: linear-gradient(rgba(31, 31, 71, 0.25) -18.72%, rgb(31, 31, 71) 37.6%);
  backdrop-filter: saturate(200%);
}

.cgmqud {
  position: absolute;
  height: 1500px;
  width: 100%;
  overflow: hidden;
  animation: 1s ease 0s 1 normal forwards running jBcSpD;
}

.QTgzF {
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 0px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/testimonial-wave1.svg");
  height: 800px;
}

.jFshNv {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
  top: 250px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/testimonial-wave2.svg");
  height: 600px;
}

.eRXLNx {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 2;
  top: 380px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/testimonial-wave3.svg");
  height: 600px;
}

.hdkUYT {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 3;
  top: 200px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/testimonial-wave4.svg");
  height: 1000px;
}

.ksZdMZ {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 4;
  top: 450px;
  background-image: url("https://d2u6rc592zow96.cloudfront.net/img/testimonial-wave5.svg");
  height: 1000px;
}

.hScvBT {
  position: absolute;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  transform-origin: left top;
  top: 800px;
  height: 500px;
  clip-path: url(#wave6);
  background: linear-gradient(rgba(31, 31, 71, 0.25) -18.72%, rgb(31, 31, 71) 37.6%);
}

.ZEENI {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0px;
  height: 800px;
}

.beILHN {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave11);
  top: 0px;
  height: 250px;
  background: linear-gradient(rgb(23, 106, 177) 11.94%, rgb(203, 216, 241) 80.98%);

}

.hJrhkr {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave13);
  top: 30px;
  height: 600px;
  background: linear-gradient(rgba(19, 12, 62, 0.8) 0%, rgb(30, 19, 87) 28%);
}

.gvnAxs {
  position: relative;
  width: 250px;
  height: 360px;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(209.21deg, rgb(79, 73, 139) 13.57%, rgb(24, 16, 45) 98.38%);
  box-shadow: rgba(24, 16, 45, 0.3) 0px 20px 40px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.efwLTn {
  position: relative;
  width: 250px;
  height: 360px;
  margin-top: 20px;
  border-radius: 20px;
  background: linear-gradient(209.21deg, rgb(29, 19, 36) 13.57%, rgb(0, 35, 124) 98.38%);
  box-shadow: rgba(0, 35, 124, 0.3) 0px 20px 40px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hSsukS {
  position: relative;
  width: 250px;
  height: 360px;
  margin-top: 20px;
  border-radius: 20px;
  background: linear-gradient(209.21deg, rgb(23, 23, 27) 13.57%, rgb(119, 63, 211) 98.38%);
  box-shadow: rgba(119, 63, 211, 0.3) 0px 20px 40px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lAsmb {
  position: relative;
  width: 250px;
  height: 360px;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(209.21deg, rgb(76, 55, 109) 13.57%, rgb(31, 21, 53) 98.38%);
  box-shadow: rgba(31, 21, 53, 0.3) 0px 20px 40px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fySLzt {
  width: 380px;
  background: rgba(15, 14, 71, 0.5);
  border-radius: 20px;
  padding: 30px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
  z-index: 800;
  margin-top: 20px;
  backdrop-filter: blur(40px);
}

.fNaEyC:hover {
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  background-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.3);
  padding: 10px 10px;
}

.iconHover:hover {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  margin-top: -8px;
}

.gvnAxs:hover {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: scale(1.1);
}

.efwLTn:hover {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: scale(1.1);
}

.hSsukS:hover {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: scale(1.1);
}

.lAsmb:hover {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: scale(1.1);
}

svg:not(:root) {
  overflow: hidden;
}

.boder {
  width: 1px;
  height: 20px;
  border: 1.5px solid #5A5C7B;
  margin-left: 10px;
  margin-right: 10px;
}

.powerfulWidth {
  width: 1230px;
}

.bannemockupImg {
  width: 455px;
  height: 730px
}

.fGgPzw {
  max-width: 650px;
  width: 100%;
  padding: 20px;
  background: rgba(50, 61, 109, 0.5);
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards running jkLqKc;
}

.fZKSu {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px) saturate(100%);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 900;
  animation: 1s ease 0s 1 normal forwards running bDTdTe;
}

.logo {
  width: 160px;
  height: 32px;
  cursor: "pointer"
}

.singUpImg {
  width: 50px;
  height: 50px;
  cursor: "pointer"
}

.ant-input {
  &::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

@keyframes bDTdTe {
  0% {
    backdrop-filter: blur(0px) saturate(100%);
  }

  100% {
    backdrop-filter: blur(20px) saturate(120%);
  }
}


@keyframes jkLqKc {
  0% {
    transform: translateY(-100px) scale(0.8);
    opacity: 0;
  }

  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .haRxZf {
    background: linear-gradient(rgba(31, 31, 71, 0.25) -18.72%, rgb(31, 31, 71) 37.6%);
  }
}

@media (max-width:900px) {
  .advantages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .runTrading {
    width: 100%;
  }

  .integrate {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .hIBkFg {
    font-size: 32px;
    width: 360px;
    line-height: 48px;
    font-weight: 800;
  }

  .tradelike {
    width: 340px;
  }

  .powerfulWidth {
    width: 360px;
  }

  .bannemockupImg {
    width: 350px;
    height: 500px
  }

  .fGgPzw {
    width: 360px;
    padding: 10px;
  }

  .logo {
    width: 120px;
    height: 20px;
    cursor: "pointer"
  }

  .singUpImg {
    width: 30px;
    height: 30px;
    cursor: "pointer"
  }

  .ceCePd {
    max-width: 400px;
    padding: 40px 10px;
    top: 10;
  }

  .selectLang {
    padding: 8px 8px;
  }

}

@media (min-width:900px) {
  .advantages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1230px;
  }

  .runTrading {
    width: 1230px;

  }

  .integrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1230px;
  }

  .hIBkFg {
    font-size: 58px;
    width: 800px;
  }

  .tradelike {
    width: 700px;
  }

  .powerfulWidth {
    width: 1230px;
  }

  .bannemockupImg {
    width: 355px;
    height: 590px
  }

}

@media (max-width: 1440px) {
  .bXnDvb {
    background-size: 1440px;
  }

  .jHdieN {
    background-size: 1440px;
  }

  .haRxZf {
    background-size: 1440px;

  }

  .QTgzF {
    background-size: 1440px;
  }

  .jFshNv {
    background-size: 1440px;
  }

  .eRXLNx {
    background-size: 1440px;
  }

  .hdkUYT {
    background-size: 1440px;
  }

  .ksZdMZ {
    background-size: 1440px;
  }

}

@media (min-width: 1440px) {
  .haRxZf {
    transform: scale(1.5)
  }

  .jTMytU {
    transform: scale(1.5);
    left: -1px;
  }

  .jTXiRU {
    transform: scale(1.5);
    left: -1px;
  }


  .hDCskI {
    transform: scale(1.5);
    left: -1px;
  }

  .hScvBT {
    transform: scale(1.5);
    left: -1px;
  }

  .beILHN {
    transform: scale(1.5);
    left: -1px;
  }

  .hJrhkr {
    transform: scale(1.5);
    left: -1px;
  }
}

@media (min-width: 2200px) {
  .haRxZf {
    transform: scale(2);
  }

  .jTMytU {
    transform: scale(2);
    left: -1px;
  }

  .jTXiRU {
    transform: scale(2);
    left: -1px;
  }

  .jTXiRU {
    transform: scale(2);
    left: -1px;
  }

  .hDCskI {
    transform: scale(2);
    left: -1px;
  }

  .hScvBT {
    transform: scale(2);
    left: -1px;
  }

  .beILHN {
    transform: scale(2);
    left: -1px;
  }

  .hJrhkr {
    transform: scale(2);
    left: -1px;
  }
}